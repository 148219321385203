<template>
    <div class="order-filter">
        <div class="wrapper">
            <div class="filter-container">
                <div class="order-filter__statuses--wrapper">
                    <template v-for="(preset, index) in presets.slice(0, 6)">
                        <div
                            v-if="preset.count"
                            :key="index"
                            class="order-filter__statuses--item"
                            :class="{
                                active: preset.value === searchReturnObject.preset,
                                ...getStatusItemColor(preset.value)
                            }"
                            @click="setPreset(preset.value, preset.title)"
                        >
                            <span>{{ preset.title }}</span>
                            <span
                                class="order-filter__statuses--count"
                                :class="{
                                    active: preset.value === searchReturnObject.preset,
                                    ...getStatusItemColor(preset.value)
                                }"
                            >{{ preset.count }} </span>
                            <span
                                :class="{close : preset.value === searchReturnObject.preset }"
                                @click.stop="removePreset(preset.value)"
                            />
                        </div>
                    </template>
                </div>
                <div class="order-filter__status-all">
                    <span
                        v-if="!loading"
                        class="order-filter__status-all-chevron"
                        @click="closePresetsWindow"
                    >
                        <span :class="!isPresetWindowOpen ? 'chevron-open': 'chevron-close'" />
                    </span>
                    <transition name="open">
                        <OrderPresetWindow
                            v-if="isPresetWindowOpen"
                            :presets="presets"
                            :search-return-object="searchReturnObject"
                            @closePresetsList="closePresetsWindow"
                            @setPreset="setPreset"
                            @removePreset="removePreset"
                        />
                    </transition>
                    <template v-if="loading && isRouteOrders">
                        <img
                            class="count"
                            style="padding: 0"
                            width="40"
                            height="40"
                            src="@/assets/img/svg/animation/Pulse.svg"
                            alt="loader"
                        >
                    </template>
                    <template v-else-if="isRouteOrders">
                        <span class="count">{{ convertToMoney(tableInfo[1].money) }}</span>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { rowsPerPageList } from '@/helpers/rowsPerPageList.js'
import { getColor } from '@/services/orders/statusColorHelper'
import { convertToMoney, convertToNumberFormat } from '@/helpers/utils'
import OrderPresetWindow from '@/components/account/orders/OrderPresetWindow';

export default {
    rowsPerPageList,
    name: 'OrderPresets',
    components: {
        OrderPresetWindow
    },
    props: {
        presets: {
            type: Array,
            default: () => ([])
        },
        tableInfo: {
            type: Array,
            required: false
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        const {
            status, per_page, page
        } = this.$route.query
        const searchReturnObject = {
            status: status || 'any',
            search_by: 'title',
            per_page: per_page || 10,
            page: page || 1
        }
        return {
            searchReturnObject,
            isPresetWindowOpen: false
        }
    },
    computed: {
        isRouteOrders() {
            return this.$route.name === 'orders'
        }
    },
    watch: {
        '$route.query': {
            handler() {
                this.setQuery()
            },
            deep: true
        }
    },
    mounted() {
        this.setQuery()
    },
    methods: {
        convertToMoney,
        convertToNumberFormat,
        setQuery() {
            const {
                per_page, page, preset, sort_by, sort_order
            } = this.$route.query
            this.searchReturnObject = {
                per_page: per_page || 10,
                page: page || 1,
                preset: preset || '',
                sort_by,
                sort_order
            }
        },
        setPreset(preset, title) {
            this.searchReturnObject = { ...this.searchReturnObject, preset }
            this.$emit('getTitle', title)
            this.$emit('updateSearch', { query: this.searchReturnObject })
            this.isPresetWindowOpen = false
        },
        removePreset(preset) {
            if (this.searchReturnObject.preset === preset) {
                this.searchReturnObject.preset = ''
                this.$emit('getTitle', 'All')
                this.$emit('updateSearch', { query: this.searchReturnObject })
                this.isPresetWindowOpen = false
            }
        },
        getStatusItemColor(preset) {
            return { [`order-filter__statuses--item--${getColor(preset)}`]: true }
        },
        closePresetsWindow() {
            this.isPresetWindowOpen = !this.isPresetWindowOpen
        }
    }
}
</script>

<style scoped lang="scss">
.orders-filter__toggle-btn {
    color: #6e6e6e !important;
    margin-top: 5px;
    cursor: pointer;
    transition: .3s all;

    &:hover{
        svg{
            fill: #FF690C;
        }
    }
    svg {
        width: 30px;
        height: 30px;
    }
}
.order-filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .filter-block {
        display: flex;
        align-items: center;
        width: 100%;
        svg {
            margin-right: 10px;
            color: #1161E0;
        }
        &__text {
            margin-right: 10px;
            text-transform: uppercase;
            font-weight: bold;
        }
        &__count {
            display: flex;
            width: 30px;
            height: 30px;
            background: #F4F4F4;
            border-radius: 4px;
            align-items: center;
            justify-content: center;
            color: #000;
            font-size: 16px;
            font-weight: bold;
        }
    }
}
.orders-filters {
    width: 100%;
    margin-top: 30px;
}
.order_reset__button {
    cursor: pointer;
    margin-left: 10px;
    color: #FF690C;
}
.order-filter {
    &__statuses--wrapper {
        width: 80%;
        height: 45px;
        display: flex;
        padding-bottom: 3px;
        overflow: hidden;
        white-space: nowrap;
        position: relative;
        .order-filter__statuses--item {
            padding: 10px 15px;
            background: #fff;
            cursor: pointer;
            border: 3px transparent solid;
            border-radius: 6px;
            display: flex;
            align-items: center;
            &--success {
                .order-filter__statuses--count {
                    background: rgba(89, 199, 77, 0.1);
                    color: #59c74d;
                }
            }
            &--free {
                .order-filter__statuses--count {
                    background: rgba($main-color, 0.1);
                    color: $main-color;
                }
            }
            &--attention {
                .order-filter__statuses--count {
                    background: rgba(255, 105, 12, 0.1);
                    color: #ff690c;
                }
            }
            &--revision {
                .order-filter__statuses--count {
                    background: rgba(136, 86, 242, 0.1);
                    color: #8856F2;
                }
            }
            &--error {
                .order-filter__statuses--count {
                    background: rgba(17, 97, 224, 0.1);
                    color: #000000;
                }
            }
            &--simple {
                .order-filter__statuses--count {
                    background: #F4F4F4;
                    color: #000;
                }
            }
            &.active {
                background-color: rgba(17, 97, 224, 0.1);
            }
            .order-filter__statuses--count {
                padding: 5px;
                border-radius: 5px;
                margin-left: 10px;
                &.active {
                    color: #fff;
                    background-color: #1161E0;
                }
            }
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            width: 60px;
            right: 0;
            z-index: 1;
            background: linear-gradient(270deg , rgb(255,255,255), rgb(255,255,255, 0.1));
        }
    }
    &__counters {
        padding: 7px 20px;
        width: 100%;
        display: flex;
        gap: 15px 100px;
        >li {
            display: flex;
            flex-direction: column;
            font-size: 10.5px;
            font-weight: 700;
            >.title{
                color: #9e9e9e;
            }
        }
    }
}

.order-filter__status-all{
    white-space: nowrap;
    display: flex;
    align-items: center;
    .count {
        padding: 10px;
        border-radius: 5px;
        font-weight: 600;
        color: rgba(17, 97, 224);
        font-size: 24px;
        width: 180px;
        text-align: center;
    }
    @media (max-width: 768px) {
        margin-right: 15px;
    }
}

.order-filter__statuses--count {
    font-weight: 500;
}

.order-filter__status-all-chevron{
    margin-left: 10px;
    cursor: pointer;
}

.close{
    &:after {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-left: 7px;
        background: url("~@/assets/img/svg/close.svg") no-repeat center;
    }
}

.chevron-close{
    &:after {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 7px;
        background: url("~@/assets/img/svg/close.svg") no-repeat center;
    }
}

.chevron-open{
    &:after {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 7px;
        background: url("~@/assets/img/svg/ellipsis.svg") no-repeat center;
    }
}

@media all and (max-width: 768px) {
    .order-filter__statuses--wrapper {
        .order-filter__statuses--item {
            display: flex;
            align-items: center;
            &:first-child {
                margin-left: 15px;
            }
            &:last-child {
                margin-right: 15px;
            }
        }
    }
}

@media all and (min-width: 381px) and (max-width: 900px)  {
    .order-filter__statuses--wrapper {
        .order-filter__statuses--item {
            margin: 0;
            text-align: center;
            white-space: nowrap;
        }
    }
}

.open-enter,
.open-leave-to {
    opacity: 0;
    transform: perspective(800px) rotateX(-15deg);
    transition: opacity .3s linear, transform 0.3s linear;
}

.open-enter-to,
.open-leave {
    opacity: 1;
    transform: perspective(800px) rotateX(0);
    transition: opacity .3s linear, transform 0.3s linear;
}

</style>
