<template>
    <div
        v-click-outside="closePresetsList"
        class="order-preset-window__wrapper"
    >
        <ul class="order-preset-window__presets">
            <template v-for="(preset, index) in presets">
                <li
                    v-if="preset.count"
                    :key="index"
                    class="order-preset-window__presets-item"
                    :class="{ active: preset.value === searchReturnObject.preset }"
                    @click="setPreset(preset.value, preset.title)"
                >
                    <span class="order-preset-window__presets-item-title">{{ preset.title }}</span>
                    <span
                        class="order-preset-window__presets-item-count"
                        :class="{ active: preset.value === searchReturnObject.preset }"
                    >
                        {{ preset.count }}
                    </span>
                    <span
                        class="order-preset-window__presets-close-icon"
                        :class="{ close: preset.value === searchReturnObject.preset }"
                        @click.stop="removePreset(preset.value)"
                    />
                </li>
            </template>
        </ul>
    </div>
</template>

<script>

export default {
    name: 'OrderPresetWindow',
    props: {
        presets: {
            type: Array,
            default: () => ([])
        },
        searchReturnObject: {
            type: Object
        }
    },
    methods: {
        setPreset(value, title) {
            this.$emit('setPreset', value, title)
        },
        closePresetsList() {
            this.$emit('closePresetsList')
        },
        removePreset(value) {
            this.$emit('removePreset', value)
        }
    }
}
</script>

<style lang="scss">
.order-preset-window{
    &__wrapper{
        background-color: #fff;
        position: absolute ;
        z-index: 20;
        top: 95px;
        right: 0;
        width: 300px;
        border-radius: 5px;
        box-shadow: 0px 0px 10px 3px rgb(25 50 71 / 25%);
    }
    &__presets{
        border-radius: 10px;
        //padding: 10px;
    }
    &__presets-item{
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        cursor: pointer;
        .active{
            color: #fff;
            background-color: #1161E0;
        }
    }
    &__presets-item-count{
        padding: 5px;
        border-radius: 5px;
        font-weight: 500;
        background: rgba(17, 97, 224, 0.1);
        color: #000000;
    }
    &__presets-item-title{
        width: 60%;
    }
    &__presets-close-icon{
        width: 10%;
    }
}
.active{
    background-color: rgba(17, 97, 224, 0.1);
}

.close{
    &:after {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-left: 7px;
        background: url("~@/assets/img/svg/close.svg") no-repeat center;
    }
}

</style>
