<template>
    <div
        class="form-datepicker-block"
    >
        <div
            class="form-datepicker"
            :class="{ 'error-border': validationError, 'success-border' : value}"
        >
            <fieldset class="form-datepicker__fieldset">
                <div class="form-datepicker__fieldset-date_input">
                    <legend
                        class="form-datepicker__legend"
                        :class="{ 'error': validationError }"
                    >
                        {{ label }}
                    </legend>
                    <CalendarRange class="form-datepicker__icon" />
                    <datetime
                        ref="dateTimeFrom"
                        type="datetime"
                        format="DD"
                        :flow="['date']"
                        use12-hour
                        :max-datetime="maxDate"
                        :minute-step="15"
                        monday-first
                        :disabled="disabled"
                        :value="dateFrom"
                        placeholder="From"
                        title="From"
                        @input="onDateFromSelect($event)"
                    />
                    <div
                        v-if="validationError"
                        class="form-input__error"
                    >
                        {{ validationError }}
                    </div>
                </div>
                <span class="picker-dash"> - </span>
                <div class="form-datepicker__fieldset-date_input">
                    <CalendarRange class="form-datepicker__icon" />
                    <datetime
                        ref="dateTimeTo"
                        type="datetime"
                        format="DD"
                        :flow="['date']"
                        use12-hour
                        :min-datetime="minDateTo"
                        :max-datetime="maxDate"
                        :minute-step="15"
                        monday-first
                        :value="dateTo"
                        :disabled="disabled"
                        placeholder="To"
                        title="To"
                        @input="onDateToSelect($event)"
                    />
                    <div
                        v-if="validationError"
                        class="form-input__error"
                    >
                        {{ validationError }}
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script>
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import CalendarRange from 'mdi-vue/CalendarRange.vue'
import moment from 'moment'

export default {
    name: 'RangeDatePicker',
    components: {
        datetime: Datetime,
        CalendarRange
    },
    props: {
        value: {
            type: String
        },
        label: {
            type: String,
            required: true,
            default: 'Picker'
        },
        errors: {
            type: String
        },
        field: {
            type: String
        },
        to: {
            type: String
        },
        from: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dateFrom: '',
            dateTo: '',
            datetime: null
        }
    },
    computed: {
        minDateTo() {
            return moment(this.dateFrom).toISOString();
        },
        maxDate() {
            return moment().toISOString();
        },
        validationError() {
            return this.errors ? this.errors : '';
        }
    },
    watch: {
        async dateFrom(newDate) {
            if (newDate && newDate > this.dateTo) {
                this.dateTo = null
                await this.$router.push({ query: { ...this.$route.query, from: newDate, to: '' } })
            }
        },
        to(value) {
            if (!value) {
                this.dateTo = ''
            } else {
                this.dateTo = value
            }
        },
        from(value) {
            if (!value) {
                this.dateFrom = ''
            } else {
                this.dateFrom = value
            }
        }
    },
    mounted() {
        this.dateFrom = this.from
        this.dateTo = this.to
    },
    methods: {
        onDateFromSelect(event) {
            this.dateFrom = event
            const to = this.$refs['dateTimeTo']
            if (to && !this.dateTo) {
                to.isOpen = true
            }
            this.updateDates()
        },
        onDateToSelect(event) {
            this.dateTo = event
            this.updateDates()
            // this.$emit('onDateToSelect', event)
        },
        onDateSelect(event) {
            this.$emit('update:field', event)
        },
        updateDates() {
            if (!!this.dateFrom && !!this.dateTo) {
                this.$emit('onDatesSelect', { from: this.dateFrom, to: this.dateTo })
            }
        }
    }
};
</script>

<style scoped lang="scss">

    .form-datepicker__fieldset-date_input {
        position: relative;
    }
    .form-datepicker__fieldset {
        display: flex;
        align-items: center;
    }
    .form-datepicker-block {
        width: 100%;
        display: flex;
        align-items: center;
        .form-datepicker input{
            padding-left: 20px;
            padding-right: 10px;
        }
        .picker-dash {
            margin-left: 5px;
            margin-right: 5px;
        }
    }
</style>
