export const orderStatuses = [
    {
        value: 'any',
        title: 'All'
    },
    {
        value: 'FREE',
        title: 'Available'
    },
    {
        value: 'NEW',
        title: 'New'
    },
    {
        value: 'PAID',
        title: 'Paid'
    },
    {
        value: 'PENDING',
        title: 'In Progress'
    },
    {
        value: 'COMPLETE',
        title: 'Completed'
    },
    {
        value: 'TO_REFUND',
        title: 'Refunded'
    },
    {
        value: 'REVISION',
        title: 'In Revision'
    },
    {
        value: 'OVERDUE',
        title: 'Overdue'
    }
]

export const ordersGetListTypes = {
    BY_SEARCH: '/api/order/list-by-search',
    BY_STATUS: '/api/order/list-by-status',
    BY_DATE: '/api/order/list-by-date',
    BY_UNREAD: '/api/order/list-with-unread'
}

export const DEFAULT_FILTERS_PARAMS = {
    search_by: 'title',
    search_for: '',
    per_page: '10',
    from: '',
    to: ''
}

export const searchOptions = [
    {
        text: 'Title',
        value: 'title'
    },
    {
        text: 'ID',
        value: 'orderid'
    },
    {
        text: 'Details',
        value: 'details'
    }
]
