var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-filter"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"filter-container"},[_c('div',{staticClass:"order-filter__statuses--wrapper"},[_vm._l((_vm.presets.slice(0, 6)),function(preset,index){return [(preset.count)?_c('div',{key:index,staticClass:"order-filter__statuses--item",class:{
                            active: preset.value === _vm.searchReturnObject.preset,
                            ..._vm.getStatusItemColor(preset.value)
                        },on:{"click":function($event){return _vm.setPreset(preset.value, preset.title)}}},[_c('span',[_vm._v(_vm._s(preset.title))]),_c('span',{staticClass:"order-filter__statuses--count",class:{
                                active: preset.value === _vm.searchReturnObject.preset,
                                ..._vm.getStatusItemColor(preset.value)
                            }},[_vm._v(_vm._s(preset.count)+" ")]),_c('span',{class:{close : preset.value === _vm.searchReturnObject.preset },on:{"click":function($event){$event.stopPropagation();return _vm.removePreset(preset.value)}}})]):_vm._e()]})],2),_c('div',{staticClass:"order-filter__status-all"},[(!_vm.loading)?_c('span',{staticClass:"order-filter__status-all-chevron",on:{"click":_vm.closePresetsWindow}},[_c('span',{class:!_vm.isPresetWindowOpen ? 'chevron-open': 'chevron-close'})]):_vm._e(),_c('transition',{attrs:{"name":"open"}},[(_vm.isPresetWindowOpen)?_c('OrderPresetWindow',{attrs:{"presets":_vm.presets,"search-return-object":_vm.searchReturnObject},on:{"closePresetsList":_vm.closePresetsWindow,"setPreset":_vm.setPreset,"removePreset":_vm.removePreset}}):_vm._e()],1),(_vm.loading && _vm.isRouteOrders)?[_c('img',{staticClass:"count",staticStyle:{"padding":"0"},attrs:{"width":"40","height":"40","src":require("@/assets/img/svg/animation/Pulse.svg"),"alt":"loader"}})]:(_vm.isRouteOrders)?[_c('span',{staticClass:"count"},[_vm._v(_vm._s(_vm.convertToMoney(_vm.tableInfo[1].money)))])]:_vm._e()],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }