<template>
    <Transition name="slide-fade">
        <div
            v-if="showFilter"
            v-click-outside="closeFilters"
            class="filters-container"
        >
            <div class="filters-header">
                <filter-outline />
                <span class="filters-header__title">
                    Filters
                </span>
                <a
                    v-if="paramsCount > 0"
                    class="filters-header__button"
                    @click="resetParams"
                >
                    Reset
                </a>
                <span
                    @click="closeFilters"
                >
                    <close-thick
                        class="filters-header__close"
                    />
                </span>
            </div>
            <div class="filters-content">
                <div
                    class="filters_tab"
                >
                    <h3>
                        Search by date
                    </h3>
                    <div

                        class="filters_panel__body_field"
                    >
                        <range-date-picker
                            :label="'Dates picker'"
                            :from="searchReturnObject.from"
                            :to="searchReturnObject.to"
                            @onDatesSelect="updateDates"
                        />
                    </div>
                </div>
                <div
                    class="filters_tab"
                >
                    <h3>
                        Search by query
                    </h3>
                    <div

                        class="filters_panel__body_field"
                    >
                        <double-form-element>
                            <template>
                                <form-input
                                    v-model="searchReturnObject.search_for"
                                    :field.sync="searchReturnObject.search_for"
                                    legend="Search query"
                                    placeholder="Search for"
                                    name="Search"
                                    type="text"
                                />
                                <form-select
                                    v-model="searchReturnObject.search_by"
                                    :field.sync="searchReturnObject.search_by"
                                    :value-select="searchReturnObject.search_by"
                                    :items="searchOptions"
                                    name="Search by"
                                />
                            </template>
                        </double-form-element>
                    </div>
                </div>
                <div class="filters-row">
                    <form-select
                        v-model="searchReturnObject.per_page"
                        :field.sync="searchReturnObject.per_page"
                        :value-select="searchReturnObject.per_page"
                        :items="$options.rowsPerPageList"
                        :label="'Per page'"
                        name="per page"
                    />
                    <button
                        class="btn-base btn-main"
                        :disabled="btnSearchDisabled"
                        @click="onSearch"
                    >
                        Search
                    </button>
                </div>
            </div>
        </div>
    </Transition>
</template>
<script>
// import { debounce } from 'lodash'
import RangeDatePicker from '@/components/common/form-elements/RangeDatePicker';
import FilterOutline from 'mdi-vue/FilterOutline'
import CloseThick from 'mdi-vue/CloseThick'
import { rowsPerPageList } from '@/helpers/rowsPerPageList.js'

export default {
    rowsPerPageList,
    components: {
        RangeDatePicker,
        FilterOutline,
        CloseThick
    },
    props: {
        searchOptions: {
            type: Array,
            required: true
        },
        statuses: {
            type: Array,
            default: () => ([])
        },
        defaultFiltersParams: {
            type: Object,
            required: true
        },
        showFilter: {
            type: Boolean,
            required: true
        }
    },
    data() {
        const {
            per_page, from, to, search_for
        } = this.$route.query
        const searchReturnObject = {
            search_for: search_for || '',
            search_by: 'title',
            per_page: per_page || 10,
            from: from || '',
            to: to || ''
        }
        return {
            searchReturnObject,
            btnSearchDisabled: false
            // searchIsDisabled: this.searchReturnObject.search_for === '' || this.searchReturnObject.from === '' || this.searchReturnObject.to === ''
        }
    },
    computed: {
        paramsCount() {
            const notEmptyParams = Object.entries(this.searchReturnObject).filter((i) => Boolean(i[1]))
            const paramsKeys = Object.keys(Object.fromEntries(notEmptyParams))
            return paramsKeys.filter((p) => p && !this.dontShowThisParams.includes(p)).length
        },
        dontShowThisParams() {
            return ['search_by', 'per_page', 'status']
        }
    },
    watch: {
        paramsCount(value) {
            this.$emit('updateParamsCounter', value)
        },
        searchReturnObject: {
            handler() {
                this.btnSearchDisabled = (this.searchReturnObject.search_for === '' && this.searchReturnObject.from === '' && this.searchReturnObject.to === '')
            },
            deep: true
        }
    },
    mounted() {
        this.setQuery()
        this.searchIsDisabled()
        if (this.paramsCount > 0) {
            this.$emit('updateParamsCounter', this.paramsCount)
        }
    },
    methods: {
        setQuery() {
            const {
                search_for, search_by, per_page, from, to
            } = this.$route.query
            this.searchReturnObject = {
                search_for: search_for || '',
                search_by: search_by || 'title',
                per_page: per_page || 10,
                from: from || '',
                to: to || ''
            }
        },
        searchIsDisabled() {
            return this.searchReturnObject.search_for === '' || this.searchReturnObject.from === '' || this.searchReturnObject.to === ''
        },
        async resetParams() {
            this.clearForm()
            this.$emit('updateSearch', { query: this.searchReturnObject })
        },
        clearForm() {
            this.searchReturnObject = {
                ...this.defaultFiltersParams
            }
        },
        updateDates(payload) {
            this.searchReturnObject = {
                ...this.searchReturnObject,
                ...payload
            }
        },
        closeFilters() {
            this.$emit('closeFilters')
        },
        onSearch() {
            this.$emit('updateSearch', { query: { ...this.searchReturnObject } })
            this.closeFilters()
        }
    }
}
</script>
<style lang="scss">
.filters{
    &-container{
        position: fixed;
        top: 80px;
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: 420px;
        padding: 0 15px;
        background: #fff;
        box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
        z-index: 51;
        @media (max-width: 1200px) {
            top: 60px;
        }
        .form-select__fieldset{
            &:after{
                content: '';
                width: 12px;
                height: 12px;
                background: url('~@/assets/img/svg/arrow.svg');
                display: block;
                position: absolute;
                right: 18px;
                top: calc(50% - 5px);
                transform: rotate(270deg);
                transition: .3s all;
                pointer-events: none;
            }
            &.active{
                &:after{
                    transform: rotate(90deg);
                }
            }
        }
    }
    &-header{
        display: flex;
        align-items: center;
        padding: 15px 0;
        border-bottom: 1px solid rgba(0,0,0,.12);
        &__title{
            margin-right: auto;
            font-size: 17px;
            font-weight: 700;
            text-transform: uppercase;
        }
        &__button{
            margin-left: auto;
            margin-right: 15px;
            padding: 10px 15px;
            background: #f5f5f5;
            box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
            cursor: pointer;
        }
        &__close{
            cursor: pointer;
        }
    }
    &-content{
        margin-top: 20px;
        display: grid;
        gap: 20px;
    }
    &-row{
        display: flex;
        align-items: center;
        gap: 5px;
    }
    &_tab{
        h3{
            margin-bottom: 20px;
        }
    }
}
</style>
