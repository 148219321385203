export const getColor = (sw_status) => {
    if (!sw_status && sw_status !== '') { throw Error('You need to set statuse!') }
    const statusNames = {
        simple: [''],
        free: ['FREE'],
        success: ['COMPLETE', 'CREDIT', 'PAID'],
        attention: ['PENDING', 'REVISION', 'NEW'],
        revision: ['REVISION']
    }
    return Object.entries(statusNames).reduce((acc, [key, valuesArray]) => (valuesArray.includes(sw_status) ? key : acc), 'error')
}
