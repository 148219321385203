export const ordersHeaderInfo = {
    title: 'Orders',
    className: 'header-content__orders',
    steps: [
        {
            title: 'Home',
            url: '/'
        },
        {
            title: 'Dashboard',
            url: '/cp/account'
        },
        {
            title: 'My Orders',
            url: '/cp/orders'
        }
    ]
}
