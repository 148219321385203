<template>
    <div class="sort-order__double-form-element">
        <double-form-element>
            <template>
                <form-select
                    v-model="searchObjectSort.sort_by"
                    :field.sync="searchObjectSort.sort_by"
                    :value-select="searchObjectSort.sort_by"
                    :items="OrderSearchBy"
                    name="Search by"
                />
                <form-select
                    v-model="searchObjectSort.sort_order"
                    :field.sync="searchObjectSort.sort_order"
                    :value-select="searchObjectSort.sort_order"
                    :items="OrderOption"
                    name="Search order"
                />
            </template>
        </double-form-element>
    </div>
</template>

<script>

export default {
    name: 'OrderSort',
    props: {
        searchObject: {
            type: Object
        }
    },
    data() {
        return {
            searchObjectSort: null,
            OrderSearchBy: [
                {
                    text: 'Ordered',
                    value: 'created_at'
                },
                {
                    text: 'Due',
                    value: 'due_at'
                },
                {
                    text: 'Total',
                    value: 'sw_total'
                }
            ],
            OrderOption: [
                {
                    text: 'ASC',
                    value: 'ASC'
                },
                {
                    text: 'DESC',
                    value: 'DESC'
                }
            ]
        }
    },
    computed: {
        updateSort() {
            return {
                ...this.searchObject,
                ...this.searchObjectSort
            }
        }
    },
    watch: {
        searchObjectSort: {
            handler() {
                this.$emit('updateSorting', { query: this.updateSort })
            },
            deep: true
        },
        searchObject: {
            handler() {
                const query = {
                    sort_by: this.searchObject.sort_by,
                    sort_order: this.searchObject.sort_order
                }
                if (JSON.stringify(this.searchObjectSort) !== JSON.stringify(query)) {
                    this.searchObjectSort = { ...query }
                }
            },
            deep: true
        }
    },
    created() {
        this.searchObjectSort = {
            sort_by: this.searchObject.sort_by,
            sort_order: this.searchObject.sort_order
        }
    }
}
</script>

<style lang="scss">
.sort-order__double-form-element{
    display: flex;
    align-items: center;
    width: 170px;
    margin-right: 15px;
    .sort-order__sort-by-title{
        font-size: 16px;
        font-style: italic;
        margin-right: 30px;
        white-space: nowrap;
        color: #fff;
        @media screen and (max-width: 768px){
            font-size: 14px;
            margin-right: 30px;
        }
    }
    .double-form-element {
        border: 1px solid white;
    }
    .form-select__legend {
        display: none ;
    }
    .form-select__input {
        height: 30px;
        background-color: transparent;
        color: white;
    }
    .form-select__list {
        overflow: hidden;
        top: 35px;
        z-index: 13;
        background-color: transparent;
    }
    .form-select__list-item {
        color: black;
        padding: 5px 15px;
        border-bottom: 1px solid #e7efef;
    }
}

</style>
