<template>
    <main-layout>
        <template #content>
            <header-content :header-info="headerInfo">
                <header-content-addition
                    :addition="headerInfo"
                    :has-filters="searchObject.preset === ''"
                    :filters-params-count="paramsCount"
                    @showFilters="onShowFilters"
                    @updateSearch="onUpdateSearch"
                >
                    <template #sorting>
                        <order-sort
                            :search-object="searchObject"
                            @updateSorting="onUpdateSearch"
                        />
                    </template>
                </header-content-addition>
            </header-content>
            <section class="my-orders">
                <the-filters
                    ref="filters"
                    :show-filter="showFilter"
                    :statuses="searchStatusOptionsWithCount"
                    :search-options="$options.searchOptions"
                    :default-filters-params="defaultFiltersParams"
                    @updateSearch="onUpdateSearch"
                    @updateParamsCounter="onUpdateParamsCount"
                    @closeFilters="onCloseFilters"
                />
                <order-presets
                    :presets="searchStatusOptionsWithCount"
                    :table-info="tableInfo"
                    :type="'orders'"
                    :loading="loading"
                    :search-object="searchObject"
                    @updateSearch="onUpdateSearch"
                    @getTitle="getTitle"
                />

                <div v-if="loading && (ordersList.length === 0 || isFirstLoad)">
                    <vue-skeleton-loader
                        v-for="row in Number(searchObject.per_page)"
                        :key="row"
                        :width="1200"
                        :height="85"
                        class="orders__skeleton"
                        animation="fade"
                        color="#fff"
                        wave-color="rgba(255, 105, 12, 0.1)"
                    />
                </div>

                <div
                    v-if="ordersList.length > 0"
                    class="radius-loader--wrapper"
                >
                    <div
                        v-if="loading"
                        class="background-for-radius-loader"
                        :class="{'full-height': !hasNextPage}"
                    >
                        <img
                            src="@/assets/img/svg/animation/LoaderRadius.svg"
                            alt="loader"
                        >
                    </div>
                    <div class="wrapper">
                        <template
                            v-for="(order, index) in ordersList"
                        >
                            <order-card
                                :key="order.orderid"
                                :order="order"
                                :index="index"
                                :page="searchObject.page"
                                mode="list"
                                @updateOrder="updateList"
                            />
                        </template>
                    </div>
                    <div class="orders__list--center">
                        <custom-button
                            v-if="hasNextPage"
                            :loading="loading"
                            default
                            class="button btn-base_colored sort-button"
                            @on-btn-click="loadMore"
                        >
                            Load more
                        </custom-button>
                    </div>
                </div>
                <NoResult
                    v-else-if="!loading && ordersList.length === 0"
                    type="orders"
                />
            </section>
        </template>
    </main-layout>
</template>

<script>
import HeaderContent from '@/components/HeaderContent';
import HeaderContentAddition from '@/components/HeaderContentAddition';

import MainLayout from '@/layouts/MainLayout.vue'
import OrderPresets from '@/components/account/orders/OrderPresets.vue'
import OrderSort from '@/components/account/orders/OrderSort';
import NoResult from '@/components/common/NoResult'

import { eventBus } from '@/helpers/event-bus';

import { ordersHeaderInfo } from '@/services/orders/headerInfo.js'
import {
    getOrders, getStatusesWithCounter
} from '@/services/orders/orderService.js'

import TheFilters from '@/components/common/Filters/TheFilters.vue';
import { DEFAULT_FILTERS_PARAMS, searchOptions } from '@/constants/order/order.js'
import OrderCard from '@/components/account/OrderCard/OrderCard.vue'

const defaultQuery = {
    page: 1,
    preset: '',
    per_page: 10,
    sort_by: 'created_at',
    sort_order: 'DESC'
}

export default {
    searchOptions,
    ordersHeaderInfo,
    components: {
        MainLayout,
        OrderCard,
        HeaderContent,
        HeaderContentAddition,
        OrderPresets,
        NoResult,
        TheFilters,
        OrderSort

    },
    metaInfo: {
        title: 'Orders'
    },
    data() {
        return {
            ordersData: {},
            ordersList: [],
            loading: true,
            isFirstLoad: true,
            searchObject: defaultQuery,
            savedScroll: { x: 0, y: 0 },
            paramsCount: 0,
            searchStatusOptionsWithCount: [],
            showFilter: false,
            ordersCounters: null,
            title: 'All'
        }
    },
    computed: {
        defaultFiltersParams() {
            return DEFAULT_FILTERS_PARAMS
        },
        headerInfo() {
            return { ...this.$options.ordersHeaderInfo, title: this.title, counter: this.ordersCounters?.count }
        },
        tableInfo() {
            return [
                {
                    title: 'Count',
                    count: this.ordersCounters?.count
                },
                {
                    title: 'Total sum',
                    money: this.ordersCounters?.sw_total
                }
            ]
        },
        hasNextPage() {
            return !!this.ordersData.next_page_url
        }
    },
    async created() {
        this.searchObject = {
            ...this.searchObject,
            ...this.$route.query,
            page: 1,
            per_page: +this.$route.query.per_page || 10
        }
        this.routerReplacer()
    },
    async activated() {
        setTimeout(() => {
            window.scroll(0, this.savedScroll.y);
        }, 0);
        if (this.$route.query.deleted) {
            this.onActionDeleted(this.$route.query.deleted)
            this.$router.replace({ deleted: this.$route.query.deleted })
        }
        if (this.isFirstLoad) {
            this.isFirstLoad = false
        } else {
            await this.updateList()
        }
    },
    beforeRouteLeave(to, from, next) {
        const scrollOffset = { y: window.pageYOffset || document.documentElement.scrollTop, x: 0 }
        this.savedScroll = { ...scrollOffset }
        next()
    },
    methods: {
        incrementPage() {
            this.searchObject.page += 1
        },
        async getStatuses() {
            this.searchStatusOptionsWithCount = await getStatusesWithCounter();
        },
        getTitle(title) {
            this.title = title
        },
        async getData(cache = false, with_total = true) {
            try {
                this.loading = true
                const requestArr = [getOrders({ ...this.searchObject, mode: 'list' }, cache), getStatusesWithCounter()]
                if (with_total) {
                    requestArr.push(getOrders({ ...this.searchObject, mode: 'count' }, cache))
                }
                const allQuery = await Promise.all(requestArr)
                // eslint-disable-next-line prefer-destructuring
                this.ordersData = allQuery[0]
                // eslint-disable-next-line prefer-destructuring
                this.searchStatusOptionsWithCount = allQuery[1]
                // eslint-disable-next-line prefer-destructuring
                this.ordersCounters = allQuery[2]
                this.ordersList = this.ordersData.data
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        },
        async loadMore() {
            this.loading = true
            this.incrementPage()
            const { data, next_page_url } = await getOrders({ ...this.searchObject, mode: 'list' }, false)
            this.ordersList = [...this.ordersList, ...data]
            this.ordersData.next_page_url = next_page_url
            this.routerReplacer()
            this.loading = false
        },
        async onUpdateSearch({ query }) {
            if (query.preset) {
                this.$refs['filters'].clearForm()
            }
            this.searchObject = { ...defaultQuery, ...query }
            this.searchObject.page = 1
            await this.getData()
            this.routerReplacer()
        },
        async syncOrderListKeepAlive(payload) {
            const ordersData = await getOrders(payload)
            return ordersData.data
        },
        onUpdateParamsCount(count) {
            this.paramsCount = count
        },
        onActionDeleted(id) {
            this.ordersNumber -= 1
            this.ordersList = this.ordersList.filter((item) => item.orderid !== id)
        },
        onShowFilters(preset) {
            if (preset) {
                this.showFilter = !this.showFilter
            } else {
                this.searchObject.preset = ''
                this.onUpdateSearch({ query: this.searchObject })
                this.getTitle('All')
                this.showFilter = true
            }
        },
        onCloseFilters() {
            this.showFilter = false
        },
        routerReplacer() {
            const query = {
                ...this.$route.query,
                per_page: +this.$route.query.per_page,
                page: +this.$route.query.page
            }
            if (JSON.stringify(this.searchObject) !== JSON.stringify(query)) {
                this.$router.replace({ query: { ...this.searchObject } })
            }
        },
        async updateList() {
            const requests = []
            const pageLoaded = this.searchObject.page
            for (let index = 1; index <= pageLoaded; index += 1) {
                requests.push(this.syncOrderListKeepAlive({ ...this.searchObject, mode: 'list', page: index }))
            }
            const resp = await Promise.all(requests)
            this.ordersList = this.ordersList.map((order) => {
                const find = resp.flat().find((item) => item.orderid === order.orderid)
                if (!find) {
                    return false
                }
                order = {
                    ...order,
                    ...find
                }
                return order
            }).filter((a) => Boolean(a))
            if (this.ordersList.length === 0) {
                this.searchObject = {
                    ...this.searchObject,
                    page: 1,
                    from: '',
                    to: '',
                    search_by: '',
                    search_for: ''
                }
                await this.getData()
                this.routerReplacer()
                return
            }
            await this.getStatuses()
        }
    }
}
</script>

<style lang="scss" scoped>
    .orders {
        &-title {
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 56px;
            color: $white;
        }

        &-counter {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 20px;
            background: #1161E0;
            border-radius: 4px;
            font-size: 20px;
            margin-left: 20px;
            margin-top: 5px;
        }

        &-heading {
            padding: 80px 0 50px;
        }
    }
</style>
